import React, { useState } from 'react';
import { Permission, Role } from 'appwrite';
import { useAuth } from '../../../contexts/AuthContext';
import { Loader2 } from 'lucide-react';
import CameraCapture from './CameraCapture';
import { useIsMobile } from '../hooks/useIsMobile';
import ImageUpload from './ImageUpload';
import { useProcessStore } from '../../../stores/processStore';

interface AddDamageFormProps {
  processId: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export const AddDamageForm: React.FC<AddDamageFormProps> = ({
  processId,
  onSuccess,
  onCancel,
}) => {
  const { userId, teamId } = useAuth();
  const { addDamage, isLoading, error } = useProcessStore();
  const [formState, setFormState] = useState({
    title: '',
    description: '',
    files: [] as File[],
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImages = (files: File[]): void => {
    setFormState((prev) => ({
      ...prev,
      files,
    }));
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (!userId || !teamId) return;

    try {
      await addDamage(
        processId,
        formState,
        [
          Permission.read(Role.user(userId)),
          Permission.update(Role.user(userId)),
          Permission.delete(Role.user(userId)),
          Permission.read(Role.team(teamId)),
          Permission.update(Role.team(teamId)),
          Permission.delete(Role.team(teamId)),
        ]
      );

      onSuccess();
      setFormState({
        title: '',
        description: '',
        files: [],
      });
    } catch (error) {
      console.error('Error creating damage:', error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col h-full bg-white p-2 mb-4 shadow rounded"
    >
      {/* Header with actions */}
      <div className="flex items-center justify-between px-1 py-3 border-b">
        <button
          type="button"
          onClick={onCancel}
          disabled={isLoading}
          className="text-gray-600 text-xs ps-2"
        >
          Abbrechen
        </button>
        <button
          type="submit"
          disabled={isLoading}
          className="inline-flex items-center px-4 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-full disabled:opacity-50"
        >
          {isLoading ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
              Speichern...
            </>
          ) : (
            'Speichern'
          )}
        </button>
      </div>

      {/* Main content */}
      <div className="flex-1">
        {error && (
          <div className="bg-red-50 border-b border-red-200 text-red-700 px-4 py-2 text-sm">
            {error}
          </div>
        )}

        {/* Camera/Image Section */}
        {useIsMobile() ? (
          <div className="p-4">
            <CameraCapture
              onImageCaptured={handleImages}
              onImagesSelected={handleImages}
            />
          </div>
        ) : (
          <ImageUpload
            onImagesSelected={handleImages}
            maxWidth={600}
            maxHeight={600}
            maxSizeMB={1}
            targetSizeKB={200}
          />
        )}

        {/* Input Fields */}
        <div className="px-0 pt-4 space-y-3">
          <div className="space-y-1">
            <input
              name="title"
              type="text"
              value={formState.title}
              onChange={handleInputChange}
              disabled={isLoading}
              className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:bg-gray-50"
              placeholder="Titel..."
            />
          </div>

          <div className="space-y-1">
            <input
              name="description"
              value={formState.description}
              onChange={handleInputChange}
              disabled={isLoading}
              className="w-full px-3 py-2 border border-gray-200 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:bg-gray-50"
              placeholder="Beschreibung..."
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddDamageForm;
