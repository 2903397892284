import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Client } from 'appwrite';
import { AuthProvider } from './contexts/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import VerifyEmail from './pages/VerifyEmail';
import Logout from './pages/Logout';
import ErrorPage from './components/ErrorPage';
import { useEffect } from 'react';

/**
 * Hook to prevent PWA from closing on mobile back button press
 * @returns void
 */
const usePreventAppClose = (): void => {
  useEffect(() => {
    const handleBackButton = (e: PopStateEvent): boolean => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };
    // Initial state
    window.history.pushState(null, '', window.location.href);
    // Handle back button press
    window.addEventListener('popstate', handleBackButton);
    // Additional handler for initial load
    window.addEventListener('load', () => {
      window.history.pushState(null, '', window.location.href);
    });
    // Cleanup
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
};

const client = new Client()
  .setEndpoint('https://cloud.appwrite.io/v1')
  .setProject('67196cd4001a8c44b80f');

interface AppProps {
  children?: React.ReactNode;
}

export default function App({ children }: AppProps): JSX.Element {
  usePreventAppClose();

  return (
    <Router>
      <AuthProvider client={client}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <Routes>
                  <Route index element={<Dashboard />} />
                  <Route
                    path="settings"
                    element={<Dashboard defaultTab="settings" />}
                  />
                </Routes>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Navigate to="/dashboard" replace />
              </ProtectedRoute>
            }
          />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route
            path="/logout"
            element={
              <ProtectedRoute>
                <Logout />
              </ProtectedRoute>
            }
          />
          <Route path="/unauthorized" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}
