import { useState } from 'react';
import { ID, Permission, Role } from 'appwrite';
import { databases } from '../utils/appwrite';
import { Process, ProcessType } from '../types/process';
import { useAuth } from '../contexts/AuthContext';
import { InspectionSmall } from './wizards/inspectionSmall/InspectionSmall';
import { useProcessStore } from '../stores/processStore';

interface CreateProcessFormProps {
  onCancel: () => void;
  onSuccess: (newProcess: Process) => void;
  onError: (error: string) => void;
}

export const CreateProcessForm = ({
  onCancel,
  onSuccess,
  onError,
}: CreateProcessFormProps) => {
  const [selectedType, setSelectedType] = useState<ProcessType | null>(null);
  const [serialNumber, setSerialNumber] = useState('');
  const [showWizard, setShowWizard] = useState(false);
  const { addProcess } = useProcessStore();

  const { userId, teamId } = useAuth();
  if (!userId || !teamId) return null;

  const createProcess = async (type: ProcessType): Promise<Process> => {
    try {
      // First create an empty bike record
      const bike = await databases.createDocument(
        '67197d230036564bfc99',
        'bike',
        ID.unique(),
        {
          name: '',
          manufacturer: '',
          model: '',
          frameNumber: '',
          frameType: '',
          color: '',
          type: '',
          gears: '',
          tireSize: '',
          mudguards: '',
          rackType: '',
          transmission: '',
        },
        [
          // Document creator has full rights
          Permission.read(Role.user(userId)),
          Permission.update(Role.user(userId)),
          Permission.delete(Role.user(userId)),

          // Team members full right
          Permission.read(Role.team(teamId)),
          Permission.update(Role.team(teamId)),
          Permission.delete(Role.team(teamId)),
        ]
      );

      // Then create the process with the bikeId
      const response = await databases.createDocument(
        '67197d230036564bfc99',
        '67197d2b003aece09c07',
        ID.unique(),
        {
          serialnumber: serialNumber || `Bike-${Math.floor(10000 + Math.random() * 90000)}`,
          type: type,
          status: 1,
          bikeId: bike.$id, // Link the bike to the process
        },
        [
          // Document creator has full rights
          Permission.read(Role.user(userId)),
          Permission.update(Role.user(userId)),
          Permission.delete(Role.user(userId)),

          // Team members full rights
          Permission.read(Role.team(teamId!)),
          Permission.update(Role.team(teamId!)),
          Permission.delete(Role.team(teamId!)),
        ]
      );

      const newProcess: Process = {
        $id: response.$id,
        serialnumber: response.serialnumber,
        type: response.type,
        status: response.status,
        created: response.$createdAt,
        bikeId: bike.$id, // Include bikeId in the returned process object
      };

      await addProcess(newProcess);

      if (type !== 'empty') {
        setSelectedType(type);
        setShowWizard(true);
      }

      return newProcess;
    } catch (err) {
      onError(err instanceof Error ? err.message : 'Failed to create process');
      throw err;
    }
  };

  const handleProcessTypeClick = async (type: ProcessType) => {
    console.log('handleProcessTypeClick', type);
    try {
      const process = await createProcess(type);
      onSuccess(process);
    } catch (error) {
      // Error already handled in createProcess
    }
  };

  const handleWizardComplete = (wizardData: any) => {
    // Here you would save the wizard data to the process
    console.log('Wizard completed with data:', wizardData);
    // You might want to update the process here with the wizard data
    setShowWizard(false);
  };

  const handleWizardCancel = () => {
    // You might want to delete the process here or mark it as cancelled
    setShowWizard(false);
    onCancel();
  };

  if (showWizard && selectedType === 'small-inspection') {
    return (
      <InspectionSmall
        onComplete={handleWizardComplete}
        onCancel={handleWizardCancel}
      />
    );
  }

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-medium mb-6">Neuen Vorgang anlegen</h2>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Vorgangsnummer
        </label>
        <input
          type="text"
          placeholder="Automatisch vergeben wenn leer"
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 p-2"
        />
      </div>
      {/*
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <button
          onClick={() => handleProcessTypeClick('small-inspection')}
          className="p-4 text-left border rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <h3 className="font-medium text-blue-600">Kleine Inspektion</h3>
          <p className="text-sm text-gray-600">Standard Fahrradinspektion</p>
        </button>

        <button
          onClick={() => handleProcessTypeClick('large-inspection')}
          className="p-4 text-left border rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <h3 className="font-medium text-blue-600">Große Inspektion</h3>
          <p className="text-sm text-gray-600">Umfassende Fahrradinspektion</p>
        </button>

        <button
          onClick={() => handleProcessTypeClick('damage-report')}
          className="p-4 text-left border rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <h3 className="font-medium text-blue-600">Schadensgutachten</h3>
          <p className="text-sm text-gray-600">Bewertung von Schäden</p>
        </button>

        <button
          onClick={() => handleProcessTypeClick('maintenance-report')}
          className="p-4 text-left border rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <h3 className="font-medium text-blue-600">Wartungsgutachten</h3>
          <p className="text-sm text-gray-600">Wartungsprotokoll erstellen</p>
        </button>
      </div>
*/}

      <div className="flex justify-between border-t pt-4">
        <button
          onClick={onCancel}
          className="px-4 py-2 text-sm text-gray-700 hover:text-gray-900"
        >
          Abbrechen
        </button>

        <button
          onClick={() => handleProcessTypeClick('damage-report')}
          className="px-4 py-2 text-sm text-white bg-blue-600 hover:bg-blue-700"
        >
          Vorgang anlegen
        </button>
      </div>
    </div>
  );
};
