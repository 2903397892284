import React, { useState } from 'react';
import {
  ListTodo,
  PlusCircle,
  UserCircle,
  Menu,
  LogOut,
  ChevronLeft,
  Building2,
  Users,
  Settings,
} from 'lucide-react';
import { ViewType } from '../pages/Dashboard';

interface SidebarProps {
  username: string | undefined;
  teamRole: string | null;
  onViewChange: (view: ViewType) => void;
  activeView: ViewType;
  onSignOut: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({
  username,
  teamRole,
  onViewChange,
  activeView,
  onSignOut,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleViewClick = (view: ViewType) => {
    onViewChange(view);
    setIsOpen(false); // Close menu after selection on mobile
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const NavContent = () => (
    <>
      <div className="mb-8">
        {/* Mobile Close Button */}
        <div className="flex lg:hidden items-center justify-between mb-4 px-4">
          <div className="flex items-center space-x-2">
            <UserCircle className="h-5 w-5 text-gray-600" />
            <span className="text-sm text-gray-600">
              Moin, {username || 'Lädt...'}!
            </span>
          </div>
          <button
            onClick={handleClose}
            className="p-1 hover:bg-gray-100 rounded-full"
            aria-label="Close menu"
          >
            <ChevronLeft className="h-5 w-5 text-gray-500" />
          </button>
        </div>
        {/* Desktop User Info */}
        <div className="hidden lg:flex items-center space-x-2 mb-4 px-4">
          <UserCircle className="h-5 w-5 text-gray-600" />
          <span className="text-sm text-gray-600">
            Moin, {username || 'Lädt...'}!
          </span>
        </div>
      </div>
      <nav className="space-y-1">
        <button
          onClick={() => handleViewClick('create')}
          className={`flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md ${activeView === 'create'
            ? 'bg-blue-50 text-blue-600'
            : 'hover:bg-gray-50 text-gray-700'
            }`}
        >
          <PlusCircle className="h-5 w-5" />
          <span>Neuer Vorgang</span>
        </button>
        <button
          onClick={() => handleViewClick('list')}
          className={`flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md ${activeView === 'list'
            ? 'bg-blue-50 text-blue-600'
            : 'hover:bg-gray-50 text-gray-700'
            }`}
        >
          <ListTodo className="h-5 w-5" />
          <span>Vorgänge</span>
        </button>
        <button
          onClick={() => handleViewClick('workshop')}
          className={`flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md ${activeView === 'workshop'
            ? 'bg-blue-50 text-blue-600'
            : 'hover:bg-gray-50 text-gray-700'
            }`}
        >
          <Building2 className="h-5 w-5" />
          <span>Werkstatt</span>
        </button>
        {teamRole === 'admin' && (
          <button
            onClick={() => handleViewClick('team')}
            className={`flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md ${activeView === 'team'
              ? 'bg-blue-50 text-blue-600'
              : 'hover:bg-gray-50 text-gray-700'
              }`}
          >
            <Users className="h-5 w-5" />
            <span>Team verwalten</span>
          </button>
        )}
      </nav>
      {/* Sign Out Button */}
      <div className="absolute bottom-0 left-0 right-0 p-4 border-t border-gray-200">
        <button
          onClick={() => handleViewClick('settings')}
          className={`flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md ${activeView === 'settings'
            ? 'bg-blue-50 text-blue-600'
            : 'hover:bg-gray-50 text-gray-700'
            }`}
        >
          <Settings className="h-5 w-5" />
          <span>Einstellungen</span>
        </button>
        <button
          onClick={onSignOut}
          className="flex items-center space-x-2 w-full px-4 py-2 text-left rounded-md text-gray-700 hover:bg-gray-50"
        >
          <LogOut className="h-5 w-5" />
          <span>Ausloggen</span>
        </button>
      </div>
    </>
  );

  return (
    <>
      {/* Mobile Menu Button */}
      <div className="lg:hidden fixed top-0 left-0 right-0 h-16 bg-white border-b border-gray-200 flex items-center px-4 z-20">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-2 rounded-md text-gray-600 hover:bg-gray-100"
          aria-label="Toggle menu"
        >
          {isOpen ? (
            <ChevronLeft className="h-6 w-6" />
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </button>
        <img src="logo.png" alt="BikeCheck Logo" className="h-10 w-auto ml-2" />
        <h2 className="text-xl font-semibold text-blue-600 ml-2">
          BikeCheck <span className="text-gray-800">Pro</span>
        </h2>
      </div>

      {/* Mobile Menu Overlay */}
      {isOpen && (
        <div
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={handleClose}
          aria-label="Close menu overlay"
        />
      )}

      {/* Sidebar - Desktop & Mobile */}
      <div
        className={`
					fixed lg:static inset-y-0 left-0 transform 
					${isOpen ? 'translate-x-0' : '-translate-x-full'}
					lg:translate-x-0 transition-transform duration-200 ease-in-out
					w-64 bg-white border-r border-gray-200
					z-40 flex flex-col
				`}
      >
        {/* Desktop Logo */}
        <div className="h-16 hidden lg:flex items-center px-4 border-b border-gray-200">
          <img src="logo.png" alt="BikeCheck Logo" className="h-10 w-auto" />
          <h2 className="text-xl font-semibold text-blue-600 ml-2">
            BikeCheck <span className="text-gray-800">Pro</span>
          </h2>
        </div>
        {/* Main content area with padding */}
        <div className="p-4 lg:pt-4 pt-20 flex-1 relative">
          <NavContent />
        </div>
      </div>
    </>
  );
};
