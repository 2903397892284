import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import { Storage } from 'appwrite';
import { client } from '../../utils/appwrite';
import { Damage } from '../../types/damage';

const storage = new Storage(client);

interface DamageReportProps {
  damages: Damage[];
}

const calculateImageDimensions = (images: string[]) => {
  const maxWidth = 450;

  if (images.length === 1) {
    return {
      width: maxWidth,
      height: 300,
      columns: 1
    };
  }

  if (images.length === 2) {
    return {
      width: maxWidth / 2 - 8,
      height: 200,
      columns: 2
    };
  }

  return {
    width: maxWidth / 2 - 8,
    height: 180,
    columns: 2
  };
};

export const DamageReport: React.FC<DamageReportProps> = ({ damages }) => (
  <View>
    {damages.length > 0 && (
      <Text style={{
        fontSize: 14,
        fontWeight: 'bold',
        color: '#0f172a',
        marginBottom: 16,
        textTransform: 'uppercase',
      }}>
        Schadensdokumentation
      </Text>
    )}

    {damages.map((damage, index) => {
      const hasImages = damage.images && damage.images.length > 0;

      return (
        <View key={index} style={{
          backgroundColor: '#ffffff',
          border: 1,
          borderColor: '#e2e8f0',
          borderRadius: 4,
          padding: 16,
          marginBottom: hasImages ? 30 : 20,
        }}>
          {/* Damage Header */}
          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 12,
            borderBottom: 1,
            borderBottomColor: '#e2e8f0',
            paddingBottom: 8,
          }}>
            <View style={{
              backgroundColor: '#f1f5f9',
              padding: '4 8',
              borderRadius: 4,
              marginRight: 12,
            }}>
              <Text style={{
                fontSize: 10,
                color: '#64748b',
                fontWeight: 'bold',
              }}>
                Schaden {index + 1}
              </Text>
            </View>
            <Text style={{
              fontSize: 12,
              fontWeight: 'bold',
              color: '#0f172a',
            }}>
              {damage.title}
            </Text>
          </View>

          {/* Description */}
          <Text style={{
            fontSize: 10,
            color: '#334155',
            lineHeight: 1.6,
            marginBottom: hasImages ? 16 : 0,
          }}>
            {damage.description}
          </Text>

          {/* Images Section */}
          {hasImages && (
            <View>
              <View style={{
                marginTop: 12,
                borderTop: 1,
                borderTopColor: '#e2e8f0',
                paddingTop: 12,
              }}>
                <Text style={{
                  fontSize: 10,
                  color: '#64748b',
                  marginBottom: 8,
                }}>
                  Fotodokumentation
                </Text>

                <View style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: 16,
                  justifyContent: 'flex-start',
                }}>
                  {damage.images.map((imageId, imgIndex) => {
                    const { width, height } = calculateImageDimensions(damage.images || []);
                    return (
                      <View
                        key={imgIndex}
                        style={{
                          width,
                          height,
                          backgroundColor: '#f8fafc',
                          borderRadius: 4,
                          overflow: 'hidden',
                          border: 1,
                          borderColor: '#e2e8f0',
                        }}
                      >
                        <Image
                          src={storage.getFilePreview('6719a1030025fdcdfecc', imageId, 800).toString()}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            backgroundColor: '#f8fafc',
                          }}
                        />
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          )}
        </View>
      );
    })}
  </View>
);
