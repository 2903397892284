import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 40,
    fontFamily: 'Helvetica',
  },
  section: {
    marginTop: 20, // Add space after header
    marginBottom: 20,
    backgroundColor: '#f8fafc', // Light background for separation
    padding: 15,
    borderRadius: 4,
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#0ea5e9',
    marginBottom: 20, // Increased spacing
    paddingBottom: 8,
    borderBottom: 1,
    borderBottomColor: '#e2e8f0',
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
  infoGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20, // Increased gap for better separation
  },
  infoItem: {
    width: '40%', // Slightly narrower for better spacing
    marginBottom: 12,
    backgroundColor: 'white', // White background for each item
    padding: 8,
    borderRadius: 4,
    borderLeft: 2,
    borderLeftColor: '#0ea5e9', // Blue accent
  },
  label: {
    fontSize: 8,
    color: '#64748b',
    marginBottom: 6,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
  value: {
    fontSize: 14, // Slightly larger
    color: '#0f172a',
    fontWeight: 'bold',
  },
  infoColumn: {
    width: '30%', // Using fixed width instead of flex to ensure equal columns
    marginHorizontal: '1%',
  },

  // Cover page specific styles
  coverPage: {
    position: 'relative',
    height: '100%',
    padding: 40,
  },

  // Letterhead styles
  letterhead: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 40,
  },
  workshopInfo: {
    width: '60%',
  },
  workshopName: {
    fontSize: 12,
    color: '#0f172a',
    fontWeight: 'bold',
    marginBottom: 4,
  },
  workshopAddress: {
    fontSize: 10,
    color: '#475569',
    lineHeight: 1.4,
  },
  logo: {
    width: 140,
    height: 'auto',
  },

  // Cover content styles
  coverContent: {
    marginTop: 100,
    alignItems: 'center',
    textAlign: 'center',
  },
  documentType: {
    fontSize: 14,
    color: '#0ea5e9',
    textTransform: 'uppercase',
    letterSpacing: 1,
    marginBottom: 20,
  },
  bikeId: {
    fontSize: 32,
    fontWeight: 'bold',
    color: '#0f172a',
    marginBottom: 30,
  },
  coverDivider: {
    width: '40%',
    height: 2,
    backgroundColor: '#0ea5e9',
    marginVertical: 30,
  },
  coverDetails: {
    marginTop: 40,
    width: '100%',
    maxWidth: 400,
  },
  detailRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
    paddingBottom: 8,
    borderBottom: 1,
    borderBottomColor: '#e2e8f0',
  },
  detailLabel: {
    fontSize: 11,
    color: '#64748b',
  },
  detailValue: {
    fontSize: 11,
    color: '#0f172a',
    fontWeight: 'medium',
  },

  // Content page styles
  contentPage: {
    paddingTop: 100,
  },
  header: {
    position: 'absolute',
    top: 20, // Reduced from 40
    left: 40,
    right: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10, // Reduced from 15
    borderBottom: 1, // Thinner border
    borderBottomColor: '#e2e8f0', // Lighter color
  },
  headerTitle: {
    fontSize: 18, // Reduced from 24
    fontWeight: 'bold',
    color: '#0f172a',
    marginBottom: 4,
  },
  headerSubtitle: {
    fontSize: 10, // Reduced from 12
    color: '#64748b',
  },
  headerLeft: {
    flex: 1,
  },
  pageTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#0f172a',
    marginBottom: 4,
  },
  pageSubtitle: {
    fontSize: 10,
    color: '#475569',
  },

  // Footer styles
  footer: {
    position: 'absolute',
    bottom: 40,
    left: 40,
    right: 40,
    borderTop: 1,
    borderTopColor: '#e2e8f0',
    paddingTop: 15,
  },
  damageItem: {
    marginBottom: 20,
    padding: 15,
    backgroundColor: '#f8fafc',
    borderRadius: 4,
    border: 1,
    borderColor: '#e2e8f0',
  },
  damageTitle: {
    fontSize: 14,
    color: '#0f172a',
    fontWeight: 'bold',
    marginBottom: 8,
  },
  damageDescription: {
    fontSize: 11,
    color: '#334155',
    lineHeight: 1.4,
    marginBottom: 12,
  },
  damageImages: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    marginTop: 15,
  },
  damageImage: {
    width: 120,
    height: 120,
    objectFit: 'cover',
    borderRadius: 4,
  },
  estimateSection: {
    marginTop: 20,
    padding: 15,
    backgroundColor: '#f0f9ff',
    borderRadius: 4,
  },
  estimateTitle: {
    fontSize: 14,
    color: '#0369a1',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  estimateRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  estimateLabel: {
    fontSize: 11,
    color: '#334155',
  },
  estimateValue: {
    fontSize: 11,
    color: '#0f172a',
    fontWeight: 'medium',
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    paddingTop: 10,
    borderTop: 1,
    borderTopColor: '#cbd5e1',
  },
  totalLabel: {
    fontSize: 12,
    color: '#0f172a',
    fontWeight: 'bold',
  },
  totalValue: {
    fontSize: 12,
    color: '#0ea5e9',
    fontWeight: 'bold',
  },
  footerGrid: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerColumn: {
    width: '30%',
  },
  footerText: {
    fontSize: 9,
    color: '#64748b',
    marginBottom: 3,
    lineHeight: 1.4,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 30,
    right: 50,
    fontSize: 9,
    color: '#94a3b8',
  },
});
