import React, { useState, useCallback } from 'react';
import { Upload } from 'lucide-react';
import imageCompression from 'browser-image-compression';

interface ImageUploadProps {
  onImagesSelected: (files: File[]) => void;
  maxWidth?: number;
  maxHeight?: number;
  maxSizeMB?: number;
  targetSizeKB?: number;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  onImagesSelected,
  maxWidth = 1200,
  maxHeight = 1200,
  maxSizeMB = 0.2,
  targetSizeKB = 100, // Target size in KB, adjust as needed
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const calculateDimensions = (
    width: number,
    height: number,
    maxDim: number
  ): { width: number; height: number } => {
    if (width <= maxDim && height <= maxDim) return { width, height };

    const ratio = width / height;
    if (ratio > 1) {
      return {
        width: maxDim,
        height: Math.round(maxDim / ratio),
      };
    } else {
      return {
        width: Math.round(maxDim * ratio),
        height: maxDim,
      };
    }
  };

  const getInitialQuality = (fileSize: number): number => {
    // Adjust quality based on file size
    if (fileSize > 5 * 1024 * 1024) return 0.3; // > 5MB
    if (fileSize > 2 * 1024 * 1024) return 0.4; // 2-5MB
    if (fileSize > 1 * 1024 * 1024) return 0.5; // 1-2MB
    if (fileSize > 500 * 1024) return 0.6; // 500KB-1MB
    return 0.7; // < 500KB
  };

  const compressImage = async (file: File): Promise<File> => {
    if (!file?.size) {
      throw new Error(`Ungültige Datei: ${file?.name || 'Unbekannte Datei'}`);
    }

    // Create canvas for additional compression if needed
    const createCanvas = (width: number, height: number): HTMLCanvasElement => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      return canvas;
    };

    // Convert compressed blob to file
    const blobToFile = (blob: Blob, fileName: string): File => {
      return new File([blob], fileName, {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });
    };

    try {
      let currentFile = file;
      let attempts = 0;
      const maxAttempts = 3;

      while (attempts < maxAttempts && currentFile.size > targetSizeKB * 1024) {
        const quality =
          attempts === 0
            ? getInitialQuality(currentFile.size)
            : Math.max(
                0.1,
                getInitialQuality(currentFile.size) - attempts * 0.2
              );

        // Calculate target dimensions - reduce size on each attempt
        const dimensionReduction = attempts > 0 ? 0.8 : 1;
        const targetMaxDim =
          Math.min(maxWidth, maxHeight) *
          Math.pow(dimensionReduction, attempts);

        const options = {
          maxSizeMB: maxSizeMB,
          maxWidthOrHeight: targetMaxDim,
          useWebWorker: true,
          initialQuality: quality,
          maxIteration: 2, // Reduced because we're doing multiple passes
          fileType: 'image/jpeg',
          preserveExif: false,
          alwaysKeepResolution: false,
        };

        let compressedFile = await imageCompression(currentFile, options);

        // If still too large, apply additional canvas compression
        if (
          compressedFile.size > targetSizeKB * 1024 &&
          attempts === maxAttempts - 1
        ) {
          const img = new Image();
          const loadPromise = new Promise<void>((resolve, reject) => {
            img.onload = () => resolve();
            img.onerror = reject;
          });

          img.src = URL.createObjectURL(compressedFile);
          await loadPromise;

          const dims = calculateDimensions(img.width, img.height, targetMaxDim);
          const canvas = createCanvas(dims.width, dims.height);
          const ctx = canvas.getContext('2d');

          if (ctx) {
            // Use better image smoothing
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            // Draw with a slight blur to help compression
            ctx.filter = 'blur(0.5px)';
            ctx.drawImage(img, 0, 0, dims.width, dims.height);

            // Convert to blob with additional compression
            const blob = await new Promise<Blob>((resolve) => {
              canvas.toBlob(
                (blob) => resolve(blob!),
                'image/jpeg',
                Math.max(0.1, quality - 0.2) // Even more aggressive compression
              );
            });

            compressedFile = blobToFile(blob, currentFile.name);
          }

          URL.revokeObjectURL(img.src);
        }

        currentFile = compressedFile;
        attempts++;
      }

      // Create final file with unique name
      const timestamp = new Date().getTime();
      const newFileName = `${timestamp}-${file.name.replace(/\.[^/.]+$/, '')}.jpg`;

      const finalFile = new File([currentFile], newFileName, {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });

      console.log(`Original size: ${(file.size / 1024).toFixed(2)}KB`);
      console.log(`Final size: ${(finalFile.size / 1024).toFixed(2)}KB`);

      return finalFile;
    } catch (error) {
      console.error('Compression error:', error);
      throw new Error(
        `Fehler bei der Komprimierung von ${file.name}: ${error instanceof Error ? error.message : 'Unbekannter Fehler'}`
      );
    }
  };

  const handleFiles = async (fileList: FileList | null) => {
    setError(null);

    if (!fileList?.length) {
      return;
    }

    setIsProcessing(true);

    try {
      const files = Array.from(fileList).filter(
        (file) => file?.size && file.type.startsWith('image/')
      );

      if (files.length === 0) {
        throw new Error('Keine gültigen Bilddateien ausgewählt');
      }

      const compressedFiles: File[] = [];
      const newPreviews: string[] = [];

      for (const file of files) {
        // Additional size check before compression
        if (!file?.size) continue;

        const compressedFile = await compressImage(file);

        // Verify compressed file before adding
        if (compressedFile?.size) {
          compressedFiles.push(compressedFile);
          const previewUrl = URL.createObjectURL(compressedFile);
          newPreviews.push(previewUrl);
        }
      }

      if (compressedFiles.length === 0) {
        throw new Error('Keine Dateien konnten verarbeitet werden');
      }

      // Clean up old previews
      setPreviewImages((prevPreviews) => {
        prevPreviews.forEach((url) => URL.revokeObjectURL(url));
        return newPreviews;
      });

      // Only call onImagesSelected if we have valid files
      if (compressedFiles.length > 0) {
        onImagesSelected(compressedFiles);
      }
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : 'Ein unerwarteter Fehler ist aufgetreten'
      );
      console.error('Upload error:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const dt = e.dataTransfer;
    if (dt?.files?.length) {
      handleFiles(dt.files);
    }
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragEnter = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  return (
    <div className="space-y-4">
      {error && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-700 text-sm">
          {error}
        </div>
      )}

      <div
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className={`
          relative border-2 border-dashed rounded-lg p-6
          flex flex-col items-center justify-center
          transition-colors duration-200
          ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-400'}
          ${isProcessing ? 'opacity-50 cursor-wait' : ''}
        `}
      >
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={(e) => handleFiles(e.target.files)}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          disabled={isProcessing}
        />
        <Upload
          className={`w-10 h-10 mb-2 ${isDragging ? 'text-blue-500' : 'text-gray-400'}`}
        />
        <p className="text-sm text-gray-600">
          {isProcessing
            ? 'Bilder werden verarbeitet...'
            : 'Bilder hier ablegen oder klicken zum Auswählen'}
        </p>
        <p className="text-xs text-gray-500 mt-1">
          JPG, PNG oder GIF (Bilder werden skaliert)
        </p>
      </div>

      {previewImages.length > 0 && (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {previewImages.map((preview, index) => (
            <div
              key={index}
              className="relative aspect-square rounded-lg overflow-hidden"
            >
              <img
                src={preview}
                alt={`Vorschau ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
