import React, { useState } from 'react';
import { useProcessStore } from '../../../stores/processStore';
import { BikeDetails } from '../components/BikeDetails';
import QuoteManagement from '../components/QuoteManagement';
import { AddDamageForm } from '../components/AddDamageForm';
import DamageList from '../components/DamageList';
import { getStatusStyle, getStatusText } from '../../../utils/processHelpers';
import { CheckIcon, Plus, ChevronLeft, Bike, ChevronDown } from 'lucide-react';
import { AssigneeSelector } from '../components/AssigneeSelector';

interface MobileViewProps {
  onBack: () => void;
}

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  children,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <section className="border-t">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full py-3 text-left"
      >
        <h2 className="text-lg font-medium">{title}</h2>
        <ChevronDown
          className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''
            }`}
        />
      </button>
      {isOpen && <div className="pb-4">{children}</div>}
    </section>
  );
};

const STATUS_OPTIONS = [
  { value: 1, label: 'Neu' },
  { value: 2, label: 'Wird bearbeitet' },
  { value: 3, label: 'Wird geprüft' },
  { value: 4, label: 'Versendet' },
  { value: 5, label: 'Abgeschlossen' },
];

export const MobileView: React.FC<MobileViewProps> = ({ onBack }) => {
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showAddDamageForm, setShowAddDamageForm] = useState(false);

  const { currentProcess, damages, updateProcessAssignee, updateProcessStatus } = useProcessStore();

  if (!currentProcess) return null;

  const handleStatusUpdate = async (newStatus: number) => {
    try {
      await updateProcessStatus(currentProcess.$id, newStatus);
      setShowStatusDropdown(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className="grid grid-rows-[auto_1fr_auto] h-full">
      {/* Header - Key Information */}
      <div className="bg-white border-b px-3 pt-2 pb-3">
        <div className="flex items-center mb-2">
          <button onClick={onBack} className="mr-2">
            <ChevronLeft className="w-5 h-5 text-gray-600" />
          </button>
          <div className="flex-1">
            <p className="text-xs text-gray-500">Vorgangsnummer</p>
            <p className="font-semibold">{currentProcess.serialnumber}</p>
          </div>
          <button
            onClick={() => setShowStatusDropdown(true)}
            className={`px-2.5 py-1 rounded text-sm ${getStatusStyle(currentProcess.status)}`}
          >
            {getStatusText(currentProcess.status)}
          </button>
        </div>

        <AssigneeSelector
          processId={currentProcess.$id}
          currentAssigneeId={currentProcess.assignedUserId}
          onAssigneeChange={(userId) => updateProcessAssignee(currentProcess.$id, userId)}
        />
      </div>

      {/* Scrollable Content Area */}
      <div className="overflow-y-auto">
        <div className="divide-y">
          {/* Damages Section - Always Open */}
          <section className="p-3">
            <div className="mb-4">
              <div className="flex items-center justify-between mb-2">
                <h2 className="text-lg font-semibold">
                  {(showAddDamageForm || damages.length === 0) ? 'Neuer Schaden' : 'Schadensliste'}
                </h2>
                {damages.length > 0 && !showAddDamageForm && (
                  <button
                    onClick={() => setShowAddDamageForm(true)}
                    className="p-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
                    aria-label="Neuer Schaden hinzufügen"
                  >
                    <Plus className="w-5 h-5" />
                  </button>
                )}
              </div>
              {damages.length > 0 && !showAddDamageForm && (
                <p className="text-sm text-gray-600">
                  {damages.length} {damages.length === 1 ? 'Schaden' : 'Schäden'} dokumentiert
                </p>
              )}
            </div>

            {showAddDamageForm || damages.length === 0 ? (
              <AddDamageForm
                processId={currentProcess.$id}
                onSuccess={() => setShowAddDamageForm(false)}
                onCancel={() => setShowAddDamageForm(false)}
              />
            ) : (
              <DamageList />
            )}
          </section>

          {/* Collapsible Sections */}
          <div className="px-3">
            <CollapsibleSection title="Fahrraddetails">
              <BikeDetails
                processId={currentProcess.$id}
                quoteFileId={currentProcess.quoteFileId}
              />
            </CollapsibleSection>

            <CollapsibleSection title="Anhänge">
              <QuoteManagement
                processId={currentProcess.$id}
                quoteFileId={currentProcess.quoteFileId}
                quoteName={currentProcess.quoteName}
              />
            </CollapsibleSection>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="border-t bg-white px-3 py-4 flex justify-center">
        <Bike className="text-gray-400 w-6 h-6" />
      </div>

      {/* Status Dropdown Modal */}
      {showStatusDropdown && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="absolute bottom-0 left-0 right-0 bg-white rounded-t-xl">
            <div className="px-4 py-3 border-b">
              <h3 className="text-lg font-medium">Status ändern</h3>
            </div>
            <div className="max-h-[70vh] overflow-y-auto">
              {STATUS_OPTIONS.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleStatusUpdate(option.value)}
                  className="w-full px-4 py-3 text-left border-b flex items-center justify-between hover:bg-gray-50"
                >
                  <span>{option.label}</span>
                  {currentProcess.status === option.value && (
                    <CheckIcon className="w-5 h-5 text-blue-600" />
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileView;
