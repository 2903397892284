import React, { useEffect, useState } from 'react';
import { databases } from '../utils/appwrite';
import { Workshop } from '../types/workshop';
import { Building2, Save, Loader2, X, Plus, Image } from 'lucide-react';

interface WorkshopFormProps {
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

export const WorkshopForm: React.FC<WorkshopFormProps> = ({
  onSuccess,
  onError,
}) => {
  const [workshop, setWorkshop] = useState<Workshop | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [errors, setErrors] = useState<Partial<Record<keyof Workshop, string>>>(
    {}
  );

  useEffect(() => {
    fetchWorkshopData();
  }, []);

  const fetchWorkshopData = async () => {
    try {
      setLoading(true);
      const response = await databases.listDocuments(
        '67197d230036564bfc99',
        'workshop'
      );

      if (response.documents.length > 0) {
        const doc = response.documents[0];
        const workshopData: Workshop = {
          $id: doc.$id,
          name: doc.name,
          streetAddress: doc.streetAddress,
          postalCode: doc.postalCode,
          city: doc.city,
          phoneNumber: doc.phoneNumber,
          faxNumber: doc.faxNumber,
          email: doc.email,
          website: doc.website,
          bankName: doc.bankName,
          bankCode: doc.bankCode,
          accountNumber: doc.accountNumber,
          iban: doc.iban,
          bic: doc.bic,
          taxId: doc.taxId,
          vatId: doc.vatId,
          courtLocation: doc.courtLocation,
          registrationNumber: doc.registrationNumber,
          managers: doc.managers || [],
          logo: doc.logo,
        };
        setWorkshop(workshopData);
        setLogoPreview(doc.logo);
      }
    } catch (err) {
      console.error('Error fetching workshop data:', err);
      onError?.(
        err instanceof Error ? err.message : 'Failed to load workshop data'
      );
    } finally {
      setLoading(false);
    }
  };

  const validateForm = (data: Partial<Workshop>): boolean => {
    const newErrors: Partial<Record<keyof Workshop, string>> = {};

    if (!data.name?.trim()) {
      newErrors.name = 'Name ist erforderlich';
    }
    if (!data.streetAddress?.trim()) {
      newErrors.streetAddress = 'Straße ist erforderlich';
    }
    if (!data.postalCode?.trim()) {
      newErrors.postalCode = 'PLZ ist erforderlich';
    }
    if (!data.city?.trim()) {
      newErrors.city = 'Stadt ist erforderlich';
    }
    if (!data.phoneNumber?.trim()) {
      newErrors.phoneNumber = 'Telefonnummer ist erforderlich';
    }
    if (data.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      newErrors.email = 'Ungültige E-Mail-Adresse';
    }
    if (data.iban && !/^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/.test(data.iban)) {
      newErrors.iban = 'Ungültige IBAN';
    }
    if (data.bic && !/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(data.bic)) {
      newErrors.bic = 'Ungültige BIC';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Convert AVIF to PNG using canvas
      const img = new window.Image();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);

        // Convert to PNG base64
        const pngBase64 = canvas.toDataURL('image/png');
        setLogoPreview(pngBase64);
        setWorkshop((prev) => (prev ? { ...prev, logo: pngBase64 } : null));
      };

      img.src = URL.createObjectURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!workshop || !isEditing) return;

    if (!validateForm(workshop)) {
      return;
    }

    try {
      setSaving(true);
      if (workshop.$id) {
        await databases.updateDocument(
          '67197d230036564bfc99',
          'workshop',
          workshop.$id,
          workshop
        );
      } else {
        // FiXME this should never be true anymore after we auto-create a workshop for each new user during signup
        await databases.createDocument(
          '67197d230036564bfc99',
          'workshop',
          'unique()',
          workshop
        );
      }
      setIsEditing(false);
      onSuccess?.();
    } catch (err) {
      console.error('Error saving workshop data:', err);
      onError?.(
        err instanceof Error ? err.message : 'Failed to save workshop data'
      );
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setWorkshop((prev) => (prev ? { ...prev, [name]: value } : null));
    // Clear error when field is edited
    if (errors[name as keyof Workshop]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleCancel = async () => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }
    try {
      await fetchWorkshopData();
      setIsEditing(false);
      setErrors({});
    } catch (err) {
      console.error('Error reloading workshop data:', err);
      onError?.(
        err instanceof Error ? err.message : 'Failed to reload workshop data'
      );
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  const inputClassName = `mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 px-4 py-2.5 ${!isEditing ? 'bg-gray-50' : ''
    }`;

  const sectionClassName =
    'space-y-6 bg-white rounded-lg p-6 shadow-sm border border-gray-100';
  const labelClassName = 'block text-sm font-medium text-gray-700 mb-1.5';

  return (
    <div className="max-w-3xl mx-auto bg-white rounded-lg shadow">
      <div className="px-8 py-6 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Building2 className="h-6 w-6 text-gray-500" />
            <h2 className="text-xl font-semibold text-gray-800">
              Werkstattdaten
            </h2>
          </div>
          <div className="flex flex-col sm:flex-row items-end sm:items-center gap-2 sm:space-x-4">
            <button
              type="button"
              onClick={handleCancel}
              className="px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-500 transition-colors"
            >
              {isEditing ? 'Abbrechen' : 'Bearbeiten'}
            </button>
            {isEditing && (
              <button
                type="submit"
                form="workshop-form"
                disabled={saving}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              >
                {saving ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Speichern...
                  </>
                ) : (
                  <>
                    <Save className="w-4 h-4 mr-2" />
                    Speichern
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        id="workshop-form"
        className="p-8 space-y-8"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Required Fields Section */}

          <div className={sectionClassName}>
            <div>
              <label className={labelClassName}>Name*</label>
              <input
                type="text"
                name="name"
                value={workshop?.name || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
              {errors.name && (
                <p className="mt-2 text-sm text-red-600">{errors.name}</p>
              )}
            </div>

            <div>
              <label className={labelClassName}>Straße*</label>
              <input
                type="text"
                name="streetAddress"
                value={workshop?.streetAddress || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
              {errors.streetAddress && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.streetAddress}
                </p>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className={labelClassName}>PLZ*</label>
                <input
                  type="text"
                  name="postalCode"
                  value={workshop?.postalCode || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className={inputClassName}
                />
                {errors.postalCode && (
                  <p className="mt-2 text-sm text-red-600">
                    {errors.postalCode}
                  </p>
                )}
              </div>

              <div>
                <label className={labelClassName}>Stadt*</label>
                <input
                  type="text"
                  name="city"
                  value={workshop?.city || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className={inputClassName}
                />
                {errors.city && (
                  <p className="mt-2 text-sm text-red-600">{errors.city}</p>
                )}
              </div>
            </div>
          </div>

          {/* Contact Information Section */}
          <div className={sectionClassName}>
            <div>
              <label className={labelClassName}>Telefon*</label>
              <input
                type="tel"
                name="phoneNumber"
                value={workshop?.phoneNumber || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
              {errors.phoneNumber && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.phoneNumber}
                </p>
              )}
            </div>

            <div>
              <label className={labelClassName}>Telefax</label>
              <input
                type="tel"
                name="faxNumber"
                value={workshop?.faxNumber || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
            </div>

            <div>
              <label className={labelClassName}>E-Mail</label>
              <input
                type="email"
                name="email"
                value={workshop?.email || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
              {errors.email && (
                <p className="mt-2 text-sm text-red-600">{errors.email}</p>
              )}
            </div>

            <div>
              <label className={labelClassName}>Website</label>
              <input
                type="url"
                name="website"
                value={workshop?.website || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
            </div>
          </div>
        </div>

        {/* Legal Information */}
        <div className={sectionClassName}>
          <h3 className="text-lg font-medium text-gray-900 mb-6">
            Rechtliche Informationen
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className={labelClassName}>Amtsgericht</label>
              <input
                type="text"
                name="courtLocation"
                value={workshop?.courtLocation || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
            </div>

            <div>
              <label className={labelClassName}>Handelsregisternummer</label>
              <input
                type="text"
                name="registrationNumber"
                value={workshop?.registrationNumber || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
            </div>

            <div>
              <label className={labelClassName}>Steuernummer</label>
              <input
                type="text"
                name="taxId"
                value={workshop?.taxId || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
            </div>

            <div>
              <label className={labelClassName}>USt-IdNr.</label>
              <input
                type="text"
                name="vatId"
                value={workshop?.vatId || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
            </div>
          </div>
        </div>

        {/* Banking Information */}
        <div className={sectionClassName}>
          <h3 className="text-lg font-medium text-gray-900 mb-6">Bankdaten</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className={labelClassName}>Bank</label>
              <input
                type="text"
                name="bankName"
                value={workshop?.bankName || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
            </div>

            <div>
              <label className={labelClassName}>BLZ</label>
              <input
                type="text"
                name="bankCode"
                value={workshop?.bankCode || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
            </div>

            <div>
              <label className={labelClassName}>Kontonummer</label>
              <input
                type="text"
                name="accountNumber"
                value={workshop?.accountNumber || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
            </div>

            <div>
              <label className={labelClassName}>IBAN</label>
              <input
                type="text"
                name="iban"
                value={workshop?.iban || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
              {errors.iban && (
                <p className="mt-2 text-sm text-red-600">{errors.iban}</p>
              )}
            </div>

            <div>
              <label className={labelClassName}>BIC/SWIFT</label>
              <input
                type="text"
                name="bic"
                value={workshop?.bic || ''}
                onChange={handleChange}
                disabled={!isEditing}
                className={inputClassName}
              />
              {errors.bic && (
                <p className="mt-2 text-sm text-red-600">{errors.bic}</p>
              )}
            </div>
          </div>
        </div>

        {/* Management Section */}
        <div className={sectionClassName}>
          <h3 className="text-lg font-medium text-gray-900 mb-6">
            Geschäftsführung
          </h3>
          <div className="space-y-4">
            {workshop?.managers?.map((manager, index) => (
              <div key={index} className="flex items-center gap-2">
                <input
                  type="text"
                  name={`managers.${index}`}
                  value={manager}
                  onChange={(e) => {
                    const newManagers = [...(workshop?.managers || [])];
                    newManagers[index] = e.target.value;
                    setWorkshop((prev) =>
                      prev ? { ...prev, managers: newManagers } : null
                    );
                  }}
                  disabled={!isEditing}
                  className={inputClassName}
                  placeholder="Name des Geschäftsführers"
                />
                {isEditing && (
                  <button
                    type="button"
                    onClick={() => {
                      const newManagers =
                        workshop?.managers?.filter((_, i) => i !== index) || [];
                      setWorkshop((prev) =>
                        prev ? { ...prev, managers: newManagers } : null
                      );
                    }}
                    className="text-red-500 hover:text-red-700"
                  >
                    <X className="h-5 w-5" />
                  </button>
                )}
              </div>
            ))}
            {isEditing && (
              <button
                type="button"
                onClick={() => {
                  const newManagers = [...(workshop?.managers || []), ''];
                  setWorkshop((prev) =>
                    prev ? { ...prev, managers: newManagers } : null
                  );
                }}
                className="mt-2 inline-flex items-center px-3 py-1.5 text-sm font-medium text-blue-600 hover:text-blue-500"
              >
                <Plus className="h-4 w-4 mr-1" />
                Geschäftsführer hinzufügen
              </button>
            )}
          </div>
        </div>

        <div className={sectionClassName}>
          <h3 className="text-lg font-medium text-gray-900 mb-6">Logo</h3>
          <div className="space-y-4">
            {(logoPreview || workshop?.logo) && (
              <div className="w-48 h-48 relative">
                <img
                  src={logoPreview || workshop?.logo}
                  alt="Workshop logo"
                  className="w-full h-full object-contain"
                />
                {isEditing && (
                  <button
                    type="button"
                    onClick={() => {
                      setLogoPreview(null);
                      setWorkshop((prev) =>
                        prev ? { ...prev, logo: undefined } : null
                      );
                    }}
                    className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                  >
                    <X className="h-4 w-4" />
                  </button>
                )}
              </div>
            )}
            {isEditing && !logoPreview && !workshop?.logo && (
              <div className="flex items-center space-x-2">
                <label className="flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 cursor-pointer">
                  <Image className="h-5 w-5 mr-2" />
                  Logo hochladen
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={handleLogoChange}
                  />
                </label>
                <span className="text-sm text-gray-500">
                  Empfohlen: PNG oder JPG, max. 2MB
                </span>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
