import React, { useState } from 'react';
import { Functions } from 'appwrite';
import { client } from '../../../utils/appwrite';
import { Loader2 } from 'lucide-react';

const functions = new Functions(client);

interface DamageAIAnalysisProps {
  imageId: string;
  damageId: string;
  currentDescription: string;
  currentTitle: string;
  onUpdateDamage: (updates: { description: string; title?: string }) => void;
}

const DamageAIAnalysis: React.FC<DamageAIAnalysisProps> = ({
  imageId,
  damageId,
  currentDescription,
  currentTitle,
  onUpdateDamage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [aiAnalysis, setAiAnalysis] = useState<string | null>(null);

  const analyzeImage = async () => {
    setIsLoading(true);
    try {
      const execution = await functions.createExecution(
        '6731f8380018e7fc8474',
        JSON.stringify({
          fileId: imageId,
          damageId: damageId,
        })
      );

      const responseData = JSON.parse(execution.responseBody);

      if (!responseData.success) {
        throw new Error(responseData.error || 'Bildanalyse fehlgeschlagen');
      }

      setAiAnalysis(responseData.text);
    } catch (error) {
      console.error('Analysefehler:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAppendDescription = () => {
    if (!aiAnalysis) return;

    const updatedDescription = currentDescription
      ? `${currentDescription}\n\n--- KI-Analyse ---\n${aiAnalysis}`
      : aiAnalysis;

    const updates: { description: string; title?: string } = {
      description: updatedDescription,
    };

    // If no title exists, use first two lines of AI analysis
    if (!currentTitle.trim()) {
      const lines = aiAnalysis.split('\n');
      if (lines.length >= 2) {
        const newTitle = lines.slice(0, 2).join(' - ').trim();
        updates.title = newTitle;
      }
    }

    onUpdateDamage(updates);
  };

  return (
    <div className="w-full mt-4 border rounded-lg shadow-sm bg-white">
      <div className="p-4 border-b">
        <h3 className="text-lg font-medium">KI Schadensanalyse</h3>
      </div>
      <div className="p-4 space-y-4">
        <div className="flex gap-2">
          <button
            onClick={analyzeImage}
            disabled={isLoading || !imageId}
            className={`px-4 py-2 rounded-md text-white font-medium flex items-center
              ${isLoading || !imageId
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
              }`}
          >
            {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {isLoading ? 'Analysiere...' : 'Bild analysieren'}
          </button>

          {aiAnalysis && (
            <button
              onClick={handleAppendDescription}
              className="px-4 py-2 rounded-md border border-gray-300 
                       hover:bg-gray-50 font-medium"
            >
              Zur Beschreibung hinzufügen
            </button>
          )}
        </div>

        {aiAnalysis && (
          <div className="mt-4 p-4 bg-gray-50 rounded-md whitespace-pre-wrap border">
            {aiAnalysis}
          </div>
        )}
      </div>
    </div>
  );
};

export default DamageAIAnalysis;
