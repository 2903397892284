import React from 'react';
import { View, Svg, Path, Image } from '@react-pdf/renderer';
import { styles } from './styles';
import { Workshop } from '../../types/workshop';

interface LogoProps {
  workshop: Workshop;
}

export const Logo: React.FC<LogoProps> = ({ workshop }) => {

  if (workshop.logo) {
    return (
        <Image src={workshop.logo} style={{ width: 140 }} />
    );
  }

  return (
    <View style={styles.logo}>
      <Svg viewBox="0 0 24 24" width={40} height={40}>
        <Path
          fill="#0ea5e9"
          d="M15.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM5 12c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5zm5.8-10l2.4-2.4.8.8c1.3 1.3 3 2.1 5.1 2.1V9c-1.5 0-2.7-.6-3.6-1.5l-1.9-1.9c-.5-.4-1-.6-1.6-.6s-1.1.2-1.4.6L7.8 8.4c-.4.4-.6.9-.6 1.4 0 .6.2 1.1.6 1.4L11 14v5h2v-6.2l-2.2-2.3zM19 12c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5z"
        />
      </Svg>
    </View>
  );
};
