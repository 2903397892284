import React, { useState, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import { Camera as CameraIcon, X, Image as ImageIcon } from 'lucide-react';
import imageCompression from 'browser-image-compression';

interface CameraCaptureProps {
  onImageCaptured: (files: File[]) => void;
  onImagesSelected: (files: File[]) => void;
  maxWidth?: number;
  maxHeight?: number;
  maxSizeMB?: number;
  targetSizeKB?: number;
}

const CameraCapture: React.FC<CameraCaptureProps> = ({
  onImageCaptured,
  onImagesSelected,
  maxWidth = 1200,
  maxHeight = 1200,
  maxSizeMB = 0.2,
  targetSizeKB = 100,
}) => {
  type CaptureMode = 'select' | 'camera';
  const [mode, setMode] = useState<CaptureMode>('select');
  const [captures, setCaptures] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const webcamRef = useRef<Webcam>(null);
  const [isCameraReady, setIsCameraReady] = useState(false);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: { exact: 'environment' },
  };

  const calculateDimensions = (
    width: number,
    height: number,
    maxDim: number
  ): { width: number; height: number } => {
    if (width <= maxDim && height <= maxDim) return { width, height };

    const ratio = width / height;
    if (ratio > 1) {
      return {
        width: maxDim,
        height: Math.round(maxDim / ratio),
      };
    } else {
      return {
        width: Math.round(maxDim * ratio),
        height: maxDim,
      };
    }
  };
  const handleCameraError = useCallback((err: string | DOMException) => {
    console.error('Webcam error:', err);
    setErrorMessage('Kamerazugriff fehlgeschlagen');
    setIsCameraReady(false);
  }, []);

  const getInitialQuality = (fileSize: number): number => {
    if (fileSize > 5 * 1024 * 1024) return 0.3;
    if (fileSize > 2 * 1024 * 1024) return 0.4;
    if (fileSize > 1 * 1024 * 1024) return 0.5;
    if (fileSize > 500 * 1024) return 0.6;
    return 0.7;
  };
  const compressImage = async (file: File): Promise<File> => {
    if (!file?.size) {
      throw new Error(`Ungültige Datei: ${file?.name || 'Unbekannte Datei'}`);
    }

    const createCanvas = (width: number, height: number): HTMLCanvasElement => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      return canvas;
    };

    const blobToFile = (blob: Blob, fileName: string): File => {
      return new File([blob], fileName, {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });
    };

    try {
      let currentFile = file;
      let attempts = 0;
      const maxAttempts = 3;

      while (attempts < maxAttempts && currentFile.size > targetSizeKB * 1024) {
        const quality =
          attempts === 0
            ? getInitialQuality(currentFile.size)
            : Math.max(
                0.1,
                getInitialQuality(currentFile.size) - attempts * 0.2
              );

        const dimensionReduction = attempts > 0 ? 0.8 : 1;
        const targetMaxDim =
          Math.min(maxWidth, maxHeight) *
          Math.pow(dimensionReduction, attempts);

        const options = {
          maxSizeMB: maxSizeMB,
          maxWidthOrHeight: targetMaxDim,
          useWebWorker: true,
          initialQuality: quality,
          maxIteration: 2,
          fileType: 'image/jpeg',
          preserveExif: false,
          alwaysKeepResolution: false,
        };

        let compressedFile = await imageCompression(currentFile, options);

        if (
          compressedFile.size > targetSizeKB * 1024 &&
          attempts === maxAttempts - 1
        ) {
          const img = new Image();
          const loadPromise = new Promise<void>((resolve, reject) => {
            img.onload = () => resolve();
            img.onerror = reject;
          });

          img.src = URL.createObjectURL(compressedFile);
          await loadPromise;

          const dims = calculateDimensions(img.width, img.height, targetMaxDim);
          const canvas = createCanvas(dims.width, dims.height);
          const ctx = canvas.getContext('2d');

          if (ctx) {
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            ctx.filter = 'blur(0.5px)';
            ctx.drawImage(img, 0, 0, dims.width, dims.height);

            const blob = await new Promise<Blob>((resolve) => {
              canvas.toBlob(
                (blob) => resolve(blob!),
                'image/jpeg',
                Math.max(0.1, quality - 0.2)
              );
            });

            compressedFile = blobToFile(blob, currentFile.name);
          }

          URL.revokeObjectURL(img.src);
        }

        currentFile = compressedFile;
        attempts++;
      }

      const timestamp = new Date().getTime();
      const newFileName = `${timestamp}-${file.name.replace(/\.[^/.]+$/, '')}.jpg`;

      const finalFile = new File([currentFile], newFileName, {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });

      console.log(`Original size: ${(file.size / 1024).toFixed(2)}KB`);
      console.log(`Final size: ${(finalFile.size / 1024).toFixed(2)}KB`);

      return finalFile;
    } catch (error) {
      console.error('Compression error:', error);
      throw error;
    }
  };

  const handleCameraStart = useCallback(() => {
    setErrorMessage(null);
    setIsCameraReady(true);
  }, []);

  const processFiles = async (files: File[]): Promise<File[]> => {
    const compressedFiles: File[] = [];

    for (const file of files) {
      if (file?.size && file.type.startsWith('image/')) {
        try {
          const compressedFile = await compressImage(file);
          compressedFiles.push(compressedFile);
        } catch (error) {
          console.error(`Error processing file ${file.name}:`, error);
        }
      }
    }

    return compressedFiles;
  };

  const startCamera = async (): Promise<void> => {
    setMode('camera');
    setErrorMessage(null);
    setIsCameraReady(false);
  };

  const handleCapture = async () => {
    try {
      setIsProcessing(true);
      if (webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (!imageSrc) {
          throw new Error('Failed to capture image');
        }

        // Convert base64 to blob
        const res = await fetch(imageSrc);
        const blob = await res.blob();

        const originalFile = new File([blob], `capture-${Date.now()}.jpg`, {
          type: 'image/jpeg',
          lastModified: Date.now(),
        });

        const compressedFiles = await processFiles([originalFile]);
        if (compressedFiles.length > 0) {
          const newCaptures = [...captures, ...compressedFiles];
          setCaptures(newCaptures);
          onImageCaptured(newCaptures);
        }
      }
    } catch (error) {
      console.error('Error capturing photo:', error);
      setErrorMessage('Fehler beim Aufnehmen des Fotos');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    setIsProcessing(true);
    try {
      const files = Array.from(e.target.files);
      const compressedFiles = await processFiles(files);

      if (compressedFiles.length > 0) {
        const newCaptures = [...captures, ...compressedFiles];
        setCaptures(newCaptures);
        onImagesSelected(newCaptures);
      } else {
        setErrorMessage('Keine Dateien konnten verarbeitet werden');
      }
    } catch (error) {
      setErrorMessage('Fehler beim Verarbeiten der Bilder');
      console.error('File processing error:', error);
    } finally {
      setIsProcessing(false);
      // Reset input value
      e.target.value = '';
    }
  };

  const removeImage = (index: number): void => {
    const newCaptures = captures.filter((_, i) => i !== index);
    setCaptures(newCaptures);
    onImagesSelected(newCaptures);
  };

  const renderImageGrid = () => (
    <div className="grid grid-cols-3 gap-2">
      {captures.map((file, index) => (
        <div key={index} className="relative aspect-square">
          <img
            src={URL.createObjectURL(file)}
            alt={`Capture ${index + 1}`}
            className="w-full h-full object-cover rounded-lg"
          />
          <button
            type="button"
            onClick={() => removeImage(index)}
            disabled={isProcessing}
            className="absolute top-1 right-1 p-1 bg-red-500 rounded-full text-white shadow-lg disabled:opacity-50"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      ))}
    </div>
  );

  return (
    <div className="space-y-4">
      {mode === 'select' ? (
        <>
          <div className="flex gap-2">
            <button
              type="button"
              onClick={startCamera}
              disabled={isProcessing}
              className="flex-1 flex items-center justify-center gap-2 p-3 border-2 border-dashed border-gray-300 rounded-lg text-gray-600 hover:border-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <CameraIcon className="w-5 h-5" />
              Kamera öffnen
            </button>

            <label className="flex-1 flex items-center justify-center gap-2 p-3 border-2 border-dashed border-gray-300 rounded-lg text-gray-600 hover:border-gray-400 cursor-pointer">
              <ImageIcon className="w-5 h-5" />
              Bilder auswählen
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileSelect}
                disabled={isProcessing}
                className="hidden"
              />
            </label>
          </div>

          {captures.length > 0 && renderImageGrid()}
        </>
      ) : (
        <div className="space-y-4">
          <div className="relative">
            <Webcam
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              onUserMedia={handleCameraStart}
              onUserMediaError={handleCameraError}
              className="w-full rounded-lg"
            />
            <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-4">
              <button
                type="button"
                onClick={() => setMode('select')}
                disabled={isProcessing}
                className="p-3 bg-red-500 rounded-full text-white shadow-lg disabled:opacity-50"
              >
                <X className="w-6 h-6" />
              </button>
              <button
                type="button"
                onClick={handleCapture}
                disabled={isProcessing || !isCameraReady}
                className="p-3 bg-blue-500 rounded-full text-white shadow-lg disabled:opacity-50"
              >
                <CameraIcon className="w-6 h-6" />
              </button>
            </div>
          </div>

          {captures.length > 0 && (
            <div className="bg-black/50 p-2 rounded-lg">
              {renderImageGrid()}
            </div>
          )}
        </div>
      )}

      {errorMessage && (
        <div className="text-red-500 text-sm text-center">{errorMessage}</div>
      )}

      {isProcessing && (
        <div className="text-blue-500 text-sm text-center">
          Bilder werden verarbeitet...
        </div>
      )}
    </div>
  );
};

export default CameraCapture;
