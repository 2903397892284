import React, { useState } from 'react';
import { useProcessStore } from '../../../stores/processStore';
import { BikeDetails } from '../components/BikeDetails';
import QuoteManagement from '../components/QuoteManagement';
import { AddDamageForm } from '../components/AddDamageForm';
import DamageList from '../components/DamageList';
import PDFGeneratorButton from '../../Export/ExportPDF';
import { getStatusStyle, getStatusText } from '../../../utils/processHelpers';
import { CheckIcon, ChevronDownIcon } from 'lucide-react';
import { AssigneeSelector } from '../components/AssigneeSelector';

interface DesktopViewProps {
  onBack: () => void;
}

const STATUS_OPTIONS = [
  { value: 1, label: 'Neu' },
  { value: 2, label: 'Wird bearbeitet' },
  { value: 3, label: 'Wird geprüft' },
  { value: 4, label: 'Versendet' },
  { value: 5, label: 'Abgeschlossen' },
];

export const DesktopView: React.FC<DesktopViewProps> = ({ onBack }) => {
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showAddDamageForm, setShowAddDamageForm] = useState(false);

  const { currentProcess, damages, isLoading, updateProcessAssignee, updateProcessStatus } =
    useProcessStore();

  if (!currentProcess) return null;

  const handleStatusUpdate = async (newStatus: number) => {
    try {
      await updateProcessStatus(currentProcess.$id, newStatus);
      setShowStatusDropdown(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className="flex flex-col h-full bg-white rounded-lg shadow">
      {/* Header */}
      <div className="flex items-center justify-between border-b px-6 py-4">
        <div className="flex items-center gap-4">
          <button
            onClick={onBack}
            className="text-sm text-gray-600 hover:text-gray-900"
          >
            &lt; Zurück
          </button>
          <h2 className="text-xl font-semibold text-gray-900">
            Vorgangsdetails
          </h2>
        </div>
      </div>

      {/* Main Content - Scrollable */}
      <div className="flex-1 overflow-y-auto p-6">
        <div className="space-y-6">
          {/* Top Section */}
          <div className="flex justify-between items-start">
            <div className="space-y-4">
              <div>
                <h3 className="text-sm font-medium text-gray-500">
                  Vorgangsnummer
                </h3>
                <p className="mt-1 text-lg text-gray-900">
                  {currentProcess.serialnumber}
                </p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">
                  Erstellungsdatum
                </h3>
                <p className="mt-1 text-lg text-gray-900">
                  {new Date(currentProcess.created).toLocaleDateString()}
                </p>
              </div>
            </div>

            <div className="flex space-x-4 items-start">
              <AssigneeSelector
                processId={currentProcess.$id}
                currentAssigneeId={currentProcess.assignedUserId ?? null}
                onAssigneeChange={async (userId) => {
                  await updateProcessAssignee(currentProcess.$id, userId);
                }}
              />

              <div className="relative">
                <button
                  onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                  className={`relative inline-flex items-center justify-between px-4 py-2 rounded-md border ${showStatusDropdown ? 'border-blue-500 ring-2 ring-blue-200' : 'border-gray-300'
                    } ${getStatusStyle(currentProcess.status)} cursor-pointer hover:bg-opacity-80`}
                  disabled={isLoading}
                >
                  <span>{getStatusText(currentProcess.status)}</span>
                  <ChevronDownIcon className="w-4 h-4 ml-2" />
                </button>

                {showStatusDropdown && (
                  <div className="absolute z-10 mt-1 bg-white rounded-md shadow-lg border border-gray-200">
                    <ul className="max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
                      {STATUS_OPTIONS.map((option) => (
                        <li
                          key={option.value}
                          onClick={() => handleStatusUpdate(option.value)}
                          className={`cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-50 ${currentProcess.status === option.value ? 'bg-gray-50' : ''
                            }`}
                        >
                          <span className={`block truncate ${currentProcess.status === option.value ? 'font-semibold' : 'font-normal'
                            }`}>
                            {option.label}
                          </span>
                          {currentProcess.status === option.value && (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                              <CheckIcon className="w-4 h-4 text-blue-600" />
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <PDFGeneratorButton process={currentProcess} damages={damages} />
            </div>
          </div>

          {/* Middle Section */}
          <div className="grid grid-cols-2 gap-6">
            <BikeDetails
              processId={currentProcess.$id}
              quoteFileId={currentProcess.quoteFileId}
            />
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Kostenvoranschlag
              </h3>
              <QuoteManagement
                processId={currentProcess.$id}
                quoteFileId={currentProcess.quoteFileId}
                quoteName={currentProcess.quoteName}
              />
            </div>
          </div>

          {/* Bottom Section */}
          <div className="border-t pt-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">Schäden</h3>
              <button
                onClick={() => setShowAddDamageForm(!showAddDamageForm)}
                className="px-4 py-2 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
              >
                {showAddDamageForm ? 'Abbrechen' : 'Schaden hinzufügen'}
              </button>
            </div>

            {showAddDamageForm && (
              <div className="w-1/2 mx-auto">
                <AddDamageForm
                  processId={currentProcess.$id}
                  onSuccess={() => setShowAddDamageForm(false)}
                  onCancel={() => setShowAddDamageForm(false)}
                />
              </div>
            )}
            <DamageList />
          </div>
        </div>
      </div>
    </div>
  );
};
