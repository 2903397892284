import { Loader2, Save, Upload, CheckCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useBikeStore } from '../../../stores/useBikeStore';

interface BikeDetailsProps {
  processId: string;
  quoteFileId?: string;
  bike?: any;  // Added to match the updated props
}

interface BikeDetails {
  name?: string;
  manufacturer?: string;
  model?: string;
  frameNumber?: string;
  frameType?: string;
  color?: string;
  type?: string;
  gears?: string;
  tireSize?: string;
  mudguards?: string;
  rackType?: string;
  transmission?: string;
}

const FORM_FIELDS = [
  { id: 'name', label: 'Name/Bezeichnung', name: 'name' },
  { id: 'manufacturer', label: 'Hersteller', name: 'manufacturer' },
  { id: 'model', label: 'Modell', name: 'model' },
  { id: 'frameNumber', label: 'Rahmennummer', name: 'frameNumber' },
  { id: 'color', label: 'Farbe', name: 'color' },
  { id: 'type', label: 'Fahrradart', name: 'type' },
] as const;

export const BikeDetails: React.FC<BikeDetailsProps> = ({
  processId,
  quoteFileId,
}) => {
  const {
    bike,
    isLoading,
    error,
    fetchBike,
    saveBike,
    setFormField,
    extractFromQuote,
    resetStore,
  } = useBikeStore();

  const [initialBikeState, setInitialBikeState] = useState<BikeDetails | null>(null);
  const [isProcessingQuote, setIsProcessingQuote] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);

  useEffect(() => {
    fetchBike(processId);
    return () => resetStore();
  }, [processId, resetStore]);

  useEffect(() => {
    if (bike && !initialBikeState) {
      setInitialBikeState(bike);
    }
  }, [bike, initialBikeState]);

  useEffect(() => {
    if (showSaveSuccess) {
      const timer = setTimeout(() => {
        setShowSaveSuccess(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showSaveSuccess]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormField(name as keyof BikeDetails, value);
  };

  const handleExtractFromQuote = async () => {
    if (!quoteFileId) return;
    setIsProcessingQuote(true);
    try {
      await extractFromQuote(quoteFileId);
    } finally {
      setIsProcessingQuote(false);
    }
  };

  const hasChanges = (): boolean => {
    if (!initialBikeState || !bike) return false;
    return Object.keys(bike).some((key) => {
      const k = key as keyof BikeDetails;
      return bike[k] !== initialBikeState[k];
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await saveBike(processId);
      setInitialBikeState(bike);
      setShowSaveSuccess(true);
    } catch (error) {
      console.error('Save failed:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="flex flex-col h-full md:bg-white md:rounded-lg">
      {error && (
        <div className="mx-2 md:mx-4 px-3 py-2 mb-2 text-sm text-red-600 bg-red-50 rounded-md">
          {error}
        </div>
      )}

      <div className="md:p-4">
        {/* Desktop title */}
        <h3 className="hidden md:block text-lg font-semibold text-gray-900 mb-4">
          Fahrraddetails
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 gap-3">
          {FORM_FIELDS.map((field) => (
            <div key={field.id} className="mx-2 md:mx-0">
              <label
                htmlFor={field.id}
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                {field.label}
              </label>
              <input
                id={field.id}
                type="text"
                name={field.name}
                value={bike?.[field.name as keyof BikeDetails] || ''}
                onChange={handleInputChange}
                className="block w-full h-11 md:h-10 px-3.5 md:px-1 py-2 md:py-0 text-gray-900 bg-white rounded-md border border-gray-300 
                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                placeholder:text-gray-400 disabled:bg-gray-50 disabled:text-gray-500
                transition-shadow duration-150
                md:border-gray-200"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Action buttons */}
      <div className="mt-auto pt-3 px-2 space-y-2 md:px-4 md:pt-4 md:pb-4 md:mt-2 md:space-y-0 md:space-x-3 md:flex md:justify-end md:border-t md:border-gray-200">
        <button
          onClick={handleExtractFromQuote}
          disabled={isLoading || !quoteFileId || isProcessingQuote}
          className={`w-full md:w-auto flex justify-center items-center h-11 px-4 text-sm font-medium rounded-md transition-colors
          ${isProcessingQuote || !quoteFileId || isLoading
              ? 'bg-gray-100 text-gray-500'
              : 'bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800'
            }`}
        >
          {isProcessingQuote ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Wird verarbeitet...
            </>
          ) : (
            <>
              <Upload className="w-4 h-4 mr-2" />
              Aus Anhang übernehmen
            </>
          )}
        </button>

        <button
          onClick={handleSave}
          disabled={isLoading || isSaving || !hasChanges()}
          className={`w-full md:w-auto flex justify-center items-center h-11 px-4 text-sm font-medium rounded-md transition-all duration-200
          ${isLoading || isSaving || !hasChanges()
              ? 'bg-gray-100 text-gray-500'
              : showSaveSuccess
                ? 'bg-green-600 text-white'
                : 'bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800'
            }`}
        >
          {isSaving ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Wird gespeichert...
            </>
          ) : showSaveSuccess ? (
            <>
              <CheckCircle className="w-4 h-4 mr-2" />
              Gespeichert
            </>
          ) : (
            <>
              <Save className="w-4 h-4 mr-2" />
              Speichern
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default BikeDetails;
