import { create } from 'zustand';
import { ID, Permission, Storage, Query } from 'appwrite';
import { client, databases } from '../utils/appwrite';
import { Process, ProcessFilters, ProcessType } from '../types/process';
import { Damage } from '../types/damage';

const storage = new Storage(client);

// Base type for Appwrite document fields
type AppwriteDocument = {
  $id: string;
  $createdAt: string;
  $updatedAt: string;
  $permissions: string[];
  $collectionId: string;
  $databaseId: string;
};

// Helper type for Appwrite process responses
interface AppwriteProcess extends AppwriteDocument {
  status: number;
  type: ProcessType;
  serialnumber: string;
  damage?: string;
  quoteFileId?: string;
  quoteName?: string;
  bikeId?: string;
  assignedUserId?: string | null;
}

type AppwriteDamage = AppwriteDocument & Damage;

// Helper function to map Appwrite document to Process
const mapAppwriteToProcess = (
  doc: AppwriteDocument & Partial<AppwriteProcess>
): Process => ({
  $id: doc.$id,
  created: doc.$createdAt,
  status: doc.status ?? 1,
  type: doc.type ?? 'empty',
  serialnumber: doc.serialnumber ?? '',
  damage: doc.damage,
  quoteFileId: doc.quoteFileId,
  quoteName: doc.quoteName,
  bikeId: doc.bikeId,
  assignedUserId: doc.assignedUserId ?? null,
});

interface PaginationState {
  currentPage: number;
  pageSize: number;
  total: number;
}

interface ProcessState {
  processes: Process[];
  currentProcess: Process | null;
  damages: Damage[];
  pagination: PaginationState;
  isLoading: boolean;
  isDamagesLoading: boolean;
  error: string | null;
  filters: ProcessFilters;
  sortOrder: 'asc' | 'desc';

  // Actions
  setFilters: (filters: ProcessFilters) => void;
  setSortOrder: (order: 'asc' | 'desc') => void;
  fetchProcesses: (page?: number) => Promise<void>;
  setPageSize: (size: number) => void;
  addProcess: (process: Process) => Promise<void>;
  setCurrentProcess: (process: Process | null) => void;
  updateProcessStatus: (processId: string, newStatus: number) => Promise<void>;
  updateProcessAssignee: (
    processId: string,
    userId: string | null
  ) => Promise<void>;
  fetchDamages: (processId: string) => Promise<void>;
  addDamage: (
    processId: string,
    data: {
      title: string;
      description: string;
      files: File[];
    },
    permissions: Permission[]
  ) => Promise<Damage>;
  updateDamage: (damageId: string, data: Partial<Damage>) => Promise<Damage>;
  deleteDamage: (damageId: string, fileIds?: string[]) => Promise<void>;
  updateProcessQuote: (
    processId: string,
    quoteFileId: string | null,
    quoteName: string | null
  ) => Promise<Process>;
}

export const useProcessStore = create<ProcessState>((set, get) => ({
  processes: [],
  damages: [],
  currentProcess: null,
  pagination: {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
  isLoading: false,
  isDamagesLoading: false,
  error: null,
  filters: {
    search: '',
    status: 'all',
    type: 'all',
  },
  sortOrder: 'desc', // Default to newest first

  setFilters: (newFilters) => {
    const currentFilters = get().filters;

    // Only update if filters actually changed
    if (JSON.stringify(currentFilters) !== JSON.stringify(newFilters)) {
      set({ filters: newFilters });

      // Only fetch if search string is empty or longer than 2 characters
      if (!newFilters.search || newFilters.search.length > 2) {
        get().fetchProcesses(1);
      }
    }
  },

  setSortOrder: (order) => {
    if (order !== get().sortOrder) {
      set({ sortOrder: order });
      get().fetchProcesses(1); // Refresh with new sort order
    }
  },

  fetchProcesses: async (page?: number) => {
    const { pagination, filters, sortOrder } = get();
    const targetPage = page || pagination.currentPage;

    set({ isLoading: true });

    try {
      const queries = [
        Query.limit(pagination.pageSize),
        Query.offset((targetPage - 1) * pagination.pageSize),
        sortOrder === 'desc'
          ? Query.orderDesc('$createdAt')
          : Query.orderAsc('$createdAt'),
      ];

      // Add filter queries
      if (filters.search?.trim()) {
        queries.push(Query.search('serialnumber', filters.search));
      }
      if (filters.status && filters.status !== 'all') {
        queries.push(Query.equal('status', parseInt(filters.status)));
      }
      if (filters.type && filters.type !== 'all') {
        queries.push(Query.equal('type', filters.type));
      }

      const response = await databases.listDocuments(
        '67197d230036564bfc99',
        '67197d2b003aece09c07',
        queries
      );

      set({
        processes: response.documents.map((doc) => mapAppwriteToProcess(doc)),
        pagination: {
          ...pagination,
          total: response.total,
          currentPage: targetPage,
        },
        isLoading: false,
        error: null,
      });
    } catch (error) {
      console.error('Error fetching processes:', error);
      set({
        error: 'Failed to fetch processes',
        isLoading: false,
        processes: [],
      });
    }
  },

  setPageSize: (pageSize: number) => {
    set((state) => ({
      pagination: {
        ...state.pagination,
        pageSize,
        currentPage: 1,
      },
    }));
    get().fetchProcesses(1);
  },

  addProcess: async (process: Process) => {
    set((state) => ({
      processes: [process, ...state.processes],
      currentProcess: process,
      damages: [],
      isLoading: false,
      error: null,
    }));
  },

  setCurrentProcess: (process) => {
    set({ currentProcess: process });
    if (process) {
      get().fetchDamages(process.$id);
    }
  },

  updateProcessQuote: async (processId, quoteFileId, quoteName) => {
    set({ isLoading: true, error: null });
    try {
      const response = await databases.updateDocument(
        '67197d230036564bfc99',
        '67197d2b003aece09c07',
        processId,
        {
          quoteFileId,
          quoteName,
        }
      );

      const updatedProcess = mapAppwriteToProcess(
        response as AppwriteDocument & Partial<AppwriteProcess>
      );

      set((state) => ({
        processes: state.processes.map((p) =>
          p.$id === processId ? updatedProcess : p
        ),
        currentProcess:
          state.currentProcess?.$id === processId
            ? updatedProcess
            : state.currentProcess,
      }));

      return updatedProcess;
    } catch (error) {
      set({ error: 'Failed to update quote' });
      console.error('Error updating quote:', error);
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  updateProcessStatus: async (processId, newStatus) => {
    set({ isLoading: true, error: null });
    try {
      const response = await databases.updateDocument(
        '67197d230036564bfc99',
        '67197d2b003aece09c07',
        processId,
        { status: newStatus }
      );

      const updatedProcess = mapAppwriteToProcess(
        response as AppwriteDocument & Partial<AppwriteProcess>
      );

      // Split the updates into separate set calls
      set((state) => ({
        processes: state.processes.map((p) =>
          p.$id === processId ? updatedProcess : p
        ),
      }));

      set((state) => {
        if (state.currentProcess?.$id === processId) {
          return { currentProcess: updatedProcess };
        }
        return {};
      });

      set({ isLoading: false });
    } catch (error) {
      console.error('Error updating status:', error);
      set({
        error: 'Failed to update status',
        isLoading: false
      });
    }
  },

  updateProcessAssignee: async (processId: string, userId: string | null) => {
    set({ isLoading: true, error: null });
    try {
      const response = await databases.updateDocument(
        '67197d230036564bfc99',
        '67197d2b003aece09c07',
        processId,
        { assignedUserId: userId }
      );

      const updatedProcess = mapAppwriteToProcess(
        response as AppwriteDocument & Partial<AppwriteProcess>
      );

      // Split the updates into separate set calls
      set((state) => ({
        processes: state.processes.map((p) =>
          p.$id === processId ? updatedProcess : p
        ),
      }));

      set((state) => {
        if (state.currentProcess?.$id === processId) {
          return { currentProcess: updatedProcess };
        }
        return {};
      });

      set({ isLoading: false });
    } catch (error) {
      console.error('Error updating assignee:', error);
      set({
        error: 'Failed to update assignee',
        isLoading: false
      });
    }
  },

  fetchDamages: async (processId) => {
    set({ isDamagesLoading: true });
    try {
      const response = await databases.listDocuments(
        '67197d230036564bfc99',
        '67197de300373c96dac9',
        [Query.equal('process', processId)]
      );
      set({
        damages: response.documents as AppwriteDamage[],
        isDamagesLoading: false
      });
    } catch (error) {
      set({
        error: 'Failed to fetch damages',
        isDamagesLoading: false
      });
      console.error('Error fetching damages:', error);
    }
  },

  addDamage: async (processId, data, permissions) => {
    set({ isLoading: true, error: null });
    const uploadedFileIds: string[] = [];

    try {
      // Upload images
      for (const file of data.files) {
        const uploadedFile = await storage.createFile(
          '6719a1030025fdcdfecc',
          ID.unique(),
          file,
          permissions.map((p) => p.toString())
        );
        uploadedFileIds.push(uploadedFile.$id);
      }

      // Create damage document
      const newDamage = await databases.createDocument(
        '67197d230036564bfc99',
        '67197de300373c96dac9',
        ID.unique(),
        {
          process: processId,
          title: data.title,
          description: data.description,
          images: uploadedFileIds,
        },
        permissions.map((p) => p.toString())
      );

      const damageWithTypes = newDamage as AppwriteDamage;

      set((state) => ({
        damages: [...state.damages, damageWithTypes],
      }));

      return damageWithTypes;
    } catch (error) {
      // Clean up uploaded files if document creation fails
      for (const fileId of uploadedFileIds) {
        try {
          await storage.deleteFile('6719a1030025fdcdfecc', fileId);
        } catch (deleteError) {
          console.error('Error cleaning up file:', deleteError);
        }
      }

      set({ error: 'Failed to add damage' });
      console.error('Error creating damage:', error);
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  updateDamage: async (damageId: string, data: Partial<Damage>) => {
    set({ isLoading: true, error: null });
    try {
      const updateData = {
        title: data.title,
        description: data.description,
      };

      const cleanedData = Object.fromEntries(
        Object.entries(updateData).filter(([_, v]) => v !== undefined)
      );

      const updatedDamage = (await databases.updateDocument(
        '67197d230036564bfc99',
        '67197de300373c96dac9',
        damageId,
        cleanedData
      )) as AppwriteDamage;

      set((state) => ({
        damages: state.damages.map((damage) =>
          damage.$id === damageId ? { ...damage, ...cleanedData } : damage
        ),
      }));

      return updatedDamage;
    } catch (error) {
      set({ error: 'Failed to update damage' });
      console.error('Error updating damage:', error);
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  deleteDamage: async (damageId, fileIds = []) => {
    set({ isLoading: true, error: null });
    try {
      // Delete associated files first
      for (const fileId of fileIds) {
        try {
          await storage.deleteFile('6719a1030025fdcdfecc', fileId);
        } catch (error) {
          console.error('Error deleting file:', error);
        }
      }

      await databases.deleteDocument(
        '67197d230036564bfc99',
        '67197de300373c96dac9',
        damageId
      );

      set((state) => ({
        damages: state.damages.filter((damage) => damage.$id !== damageId),
      }));
    } catch (error) {
      set({ error: 'Failed to delete damage' });
      console.error('Error deleting damage:', error);
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },
}));
