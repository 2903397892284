import React from 'react';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import { Process } from '../../types/process';
import { Header } from './Header';
import { Logo } from './Logo';
import { BikeInfo } from './BikeInfo';
import { DamageReport } from './DamageReport';
import { Footer } from './Footer';
import { CoverPage } from './CoverPage';
import { styles } from './styles';
import { Workshop } from '../../types/workshop';
import { Damage } from '../../types/damage';
import { Bike } from '../../types/bike';

interface ProcessPDFDocumentProps {
  username: string | null;
  process: Process;
  damages: Damage[];
  workshop: Workshop;
  bike: Bike;
}

// Helper function to group damages into pages
const groupDamagesIntoPages = (damages: Damage[]): Damage[][] => {
  const pages: Damage[][] = [[]];

  damages.forEach((damage, index) => {
    const hasImages = damage.images && damage.images.length > 0;

    if (index === 0 || !hasImages) {
      // First damage or text-only damages go to the first/current page
      pages[pages.length - 1].push(damage);
    } else if (hasImages) {
      // Damages with images (except first) get their own page
      pages.push([damage]);
    }
  });

  return pages;
};

export const ProcessPDFDocument: React.FC<ProcessPDFDocumentProps> = ({
  username,
  process,
  damages,
  workshop,
  bike
}) => {
  const damagePages = groupDamagesIntoPages(damages);

  return (
    <Document>
      {/* Cover Page */}
      <Page size="A4" style={styles.page}>
        <CoverPage process={process} workshop={workshop} bike={bike} />
      </Page>

      {/* Content Pages */}
      {damagePages.map((pageDamages, pageIndex) => (
        <Page key={pageIndex} size="A4" style={[styles.page, styles.contentPage]}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Text style={styles.pageTitle}>Schadensgutachten {bike.frameNumber}</Text>
              <Text style={styles.pageSubtitle}>
                {workshop.name} • {new Date().toLocaleDateString('de-DE')}
              </Text>
            </View>
            <Logo workshop={workshop} />
          </View>

          {pageIndex === 0 && <BikeInfo bike={bike} />}

          <DamageReport damages={pageDamages} />

          <Footer workshop={workshop} />
        </Page>
      ))}
    </Document>
  );
};
