// src/components/pdf/Footer.tsx
import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';
import { Workshop } from '../../types/workshop';

interface FooterProps {
  workshop: Workshop;
}

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 40,
    borderTop: 1,
    borderTopColor: '#e2e8f0',
    paddingTop: 10,
  },
  footerLayout: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  companySection: {
    width: '33%',
  },
  legalSection: {
    width: '33%',
    paddingLeft: 10,
    borderLeft: 1,
    borderLeftColor: '#e2e8f0',
  },
  bankSection: {
    width: '33%',
    paddingLeft: 10,
    borderLeft: 1,
    borderLeftColor: '#e2e8f0',
  },
  text: {
    fontSize: 8,
    color: '#64748b',
    marginBottom: 2,
  },
  bankDetails: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  bankLabel: {
    fontSize: 8,
    color: '#64748b',
    width: 40,
  },
  bankValue: {
    fontSize: 8,
    color: '#64748b',
    flex: 1,
  },
});

export const Footer: React.FC<FooterProps> = ({ workshop }) => (
  <View style={styles.footer}>
    <View style={styles.footerLayout}>
      {/* Company Information */}
      <View style={styles.companySection}>
        <Text style={styles.text}>{workshop.name}</Text>
        <Text style={styles.text}>{workshop.streetAddress}</Text>
        <Text style={styles.text}>{workshop.postalCode} {workshop.city}</Text>
        <Text style={styles.text}>Tel: {workshop.phoneNumber}</Text>
        <Text style={styles.text}>Telefax: {workshop.faxNumber}</Text>
        <Text style={styles.text}>E-Mail: {workshop.email}</Text>
        <Text style={styles.text}>Internet: {workshop.website}</Text>
      </View>

      {/* Legal Information */}
      <View style={styles.legalSection}>
        <Text style={styles.text}>Amtsgericht: {workshop.courtLocation}</Text>
        <Text style={styles.text}>HRB: {workshop.registrationNumber}</Text>
        <Text style={styles.text}>Steuernr.: {workshop.taxId}</Text>
        <Text style={styles.text}>USt. ID: {workshop.vatId}</Text>
        <Text style={styles.text}>Geschäftsführer:</Text>
        {workshop.managers?.map((manager, index) => (
          <Text key={index} style={styles.text}>{manager}</Text>
        ))}
      </View>

      {/* Bank Information */}
      <View style={styles.bankSection}>
        <View style={styles.bankDetails}>
          <Text style={styles.bankLabel}>Bank</Text>
          <Text style={styles.bankValue}>{workshop.bankName}</Text>
        </View>
        <View style={styles.bankDetails}>
          <Text style={styles.bankLabel}>BLZ</Text>
          <Text style={styles.bankValue}>{workshop.bankCode}</Text>
        </View>
        <View style={styles.bankDetails}>
          <Text style={styles.bankLabel}>Konto</Text>
          <Text style={styles.bankValue}>{workshop.accountNumber}</Text>
        </View>
        <Text style={styles.text}>IBAN: {workshop.iban}</Text>
        <Text style={styles.text}>SWIFT: {workshop.bic}</Text>
      </View>
    </View>
  </View>
);
