import React from 'react';
import { useProcessStore } from '../../stores/processStore';
import { useIsMobile } from './hooks/useIsMobile';
import { DesktopView } from './views/DesktopView';
import { MobileView } from './views/MobileView';

interface ProcessDetailProps {
  onBack: () => void;
}

export const ProcessDetail: React.FC<ProcessDetailProps> = ({ onBack }) => {
  const { currentProcess } = useProcessStore();
  const isMobile = useIsMobile();

  if (!currentProcess) return null;

  if (isMobile) {
    return (
      <div className="grid grid-rows-[1fr] h-[calc(100vh-4rem)] mt-16 bg-white">
        <MobileView onBack={onBack} />
      </div>
    );
  }

  return <DesktopView onBack={onBack} />;
};

export default ProcessDetail;
